import React from "react";
import Layout from "../components/Layout";
import { Helmet } from 'react-helmet'

import { Link } from "exo";

const aboutus = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Genius - Terms of Service</title>
        <meta
          name="description"
          content="Our terms of service while you use the Auto Genius community."
        />
      </Helmet>
      <Layout>
    
      <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
        <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
          Terms of Service
        </h1>
        <div className="text-black text-md b-10 max-w-4xl mx-auto terms">
        <p>Last Updated on August 15, 2021.</p>
          <p>
            <strong>
              Welcome to Auto Genius and our invitation-only communities of
              marketing and technology leaders in automotive!
            </strong>
          </p>
          <p>
            <strong>
              Please read these Terms of Service (these “Terms”) carefully.
              These Terms are a binding agreement by and between Auto Genius
              Ventures LLC, a Delaware corporation d/b/a Auto Genius (“Auto
              Genius”) (together, except in any instance where the Terms
              expressly state otherwise, with each of its direct and indirect
              subsidiaries, the “Company”, “us”, or “we”) and (i) each member
              of, or applicant for Membership in, one of the communities
              operated by us (a “Genius Community”), or (ii) any other visitor to this
              website and/or other authorized or unauthorized user of any of the
              Company’s Services, and each such person shall be referred to as
              “you”.&nbsp;
            </strong>
          </p>
          <p>
            <strong>
              PLEASE NOTE THAT THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN
              INDIVIDUAL BASIS ONLY; I.E., CASE CONSOLIDATIONS AND CLASS-ACTIONS
              ARE NOT PERMITTED IN ORDER TO RESOLVE DISPUTES.
            </strong>
          </p>
          <p>
            <strong>
              By submitting an application to become a Genius of a Genius Community
              (a “Member” or "Genius"), by using your Genius Community Membership or any of the
              benefits of your Membership (collectively, your “Membership”) in
              any way or for any purpose, by using any Company Materials
              (defined below), or by accessing or using any part of the
              Company’s Services (defined below), you accept and agree to be
              bound and abide by these Terms, including our Privacy Policy which
              is incorporated herein by reference, as well as all other
              operating rules, terms, conditions, policies, and procedures that
              may be published from time to time on or through the Company’s
              Platform or which you have been made aware by the Company, each of
              which is incorporated herein by reference in their entirety and
              each of which may be updated from time to time.
            </strong>
          </p>
          <p>
            <strong>
              If you do not agree with all of the provisions of these Terms, do
              not submit an application to become a Genius, or, if already a
              Member, do not continue to use your Membership, and otherwise do
              not access, use or continue to use any of the Services including
              any part of the Platform or any Company Materials.
            </strong>
          </p>
          <ol>
            <li>
              <strong>1. THESE TERMS OF SERVICE.</strong>
              <ul>
                <li>
                  <strong>1.1. What Is Governed By These Terms. </strong>These
                  Terms set forth the terms and conditions governing your access
                  to and use of the Platform, your Membership, and your access
                  to, participation in, and use of or other dealing with, the
                  Company’s services, Events, and the Company Materials (as
                  defined below) (collectively, the “<strong>Services</strong>
                  ”).
                </li>
                <li>
                  <strong>1.2. Additional Terms</strong>. Your use of some
                  components, portions or features of the Services may be
                  subject to additional terms and conditions as specified by us
                  from time to time. Such additional terms and conditions shall
                  be deemed incorporated in their entirety into these Terms by
                  reference. This includes any additional terms and conditions
                  with regard to specific Membership benefits, features or
                  limitations that may be described through the 
                  <Link to="/">Platform</Link>. In
                  the event of a conflict between any such additional terms and
                  conditions, or other any agreement between you and the
                  Company, and any provision in these Terms, such additional
                  terms or other agreement shall govern, but only with respect
                  to the portion of the Platform to which such additional terms
                  or other agreement applies.
                </li>
                <li>
                  <strong>
                    1.3. Using the Services on Behalf of a Legal Entity
                  </strong>
                  . In the event you are taking any action or engaging in any
                  conduct governed by these Terms at the direction of or on
                  behalf of a legal entity (including using any of the Services
                  through any sort of account created by and/or for such legal
                  entity), “you” will refer to both you as the individual
                  engaging in that action or action and the particular legal
                  entity you are acting at the direction of or on behalf of.
                </li>
                <li>
                  <strong>1.4. Privacy Policy</strong>. The Company shall treat
                  any Personal Information (as that term is defined in the
                  Company’s <Link to="/privacy-policy">Privacy Policy</Link>)
                  that it obtains about or from you, whether through the
                  Platform or otherwise, in accordance with the Company’s
                  Privacy Policy and, for the avoidance of doubt, our Privacy
                  Policy shall be deemed to be fully incorporated by reference
                  into these Terms.
                </li>
                <li>
                  <strong>
                    1.5. Right to Register As a Genius or to Use the Services
                  </strong>
                  .
                  <ul>
                    <li>
                      <strong>1.5.1.</strong> Unless the Company agrees
                      otherwise in writing, you shall apply for and be a Genius
                      of a specific Genius Community only, not for all Communities.
                      Membership criteria may vary by Genius Community.
                    </li>
                    <li>
                      <strong>1.5.2.</strong> The Company only approves the
                      Membership applications of those individuals meeting our
                      specific criteria (which may vary by Genius Community) and you
                      acknowledge and agree that you may only become a Genius if
                      the Company, in its sole discretion, approves of your
                      Membership application. In addition, we may, in our sole
                      discretion, refuse to offer Membership or access or use of
                      the Services to any Person and we may likewise change our
                      eligibility criteria for Membership (whether for all
                      Geniuses or for Geniuses of a specific Genius Community) at any
                      time.
                    </li>
                    <li>
                      <strong>1.5.3.</strong> If you are under age 18, you may
                      not, under any circumstances or for any reason, become a
                      Member or use any part of the Services, whether for your
                      own use or on behalf of a legal entity.
                    </li>
                    <li>
                      <strong>1.5.4.</strong> You are solely responsible for
                      ensuring that your Membership, your accessing or use of
                      the Services, and these Terms are in compliance with all
                      Applicable Laws. Your right to be a Genius or to access or
                      use any part of the Services shall be automatically and
                      without notice from us revoked where these Terms, your
                      Membership or use of any part of the Services is
                      prohibited by any law, rule, regulation or order of a
                      court or other Governmental Authority applicable to you.
                      Further, unless you and the Company expressly agree
                      otherwise in writing, your Membership and the Services are
                      made available only for your use and benefit and not for
                      the use or benefit of any other party.
                    </li>
                    <li>
                      <strong>1.5.5.</strong> FOR THE AVOIDANCE OF DOUBT, THE
                      COMPANY RETAINS THE RIGHT IN ITS SOLE DISCRETION TO DENY
                      USE OR ACCESS TO ANYONE TO ALL OR ANY PART OF THE SERVICES
                      AT ANY TIME AND FOR ANY REASON, INCLUDING, BUT NOT LIMITED
                      TO, FOR VIOLATION OF THESE TERMS.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <ol start="2">
            <li>
              <strong>2. AUTHORIZATION; RESTRICTIONS ON USE.</strong>
              <ul>
                <li>
                  <strong>2.1.</strong>{" "}
                  <strong>
                    Authorization to Use the Services, the Company Materials,
                    and the Platform.
                  </strong>{" "}
                  The Company hereby provides you with a limited,
                  non-transferable, non-sublicensable royalty-free authorization
                  to access and use the Services, including accessing and using
                  the Platform and the Company Materials, provided that any such
                  access and/or use is in complete compliance with these Terms.
                </li>
                <li>
                  <strong>2.2. Intellectual Property Rights.</strong> You
                  understand, acknowledge, and agree that any and all
                  Intellectual Property (including all Intellectual Property
                  Rights therein) and other proprietary elements of the
                  Services, including the Platform and all Company Materials,
                  are and shall remain the sole and exclusive property of the
                  Company and its licensors, or other providers of such services
                  and materials, and are protected by United States and
                  international copyright, trademark, patent, trade secret, and
                  other Intellectual Property-related and other proprietary
                  rights-related Applicable Laws. All rights not expressly
                  granted to you herein are reserved to the Company and its
                  licensors and other providers.
                </li>
                <li>
                  <strong>
                    2.3. Services Are For Personal Use Only; Restrictions on
                    Use.
                  </strong>{" "}
                  Unless the Company expressly states otherwise in writing, you
                  may only use the Services for your personal, non-commercial
                  use only. you shall not access or use, and shall use all
                  commercially reasonable efforts to prevent any other Person
                  from accessing, using or otherwise dealing with, the Services
                  or any part thereof (including the Platform and any Company
                  Materials) except as permitted by these Terms.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            For purposes of clarity and without limiting the generality of the
            foregoing, you shall not, except as these Terms or as the Company
            otherwise expressly permits in writing:
          </p>
          <ul>
            <li className="list-none">
              <ul>
                <li className="list-none">
                  <ul>
                    <li>
                      <strong>2.3.1.</strong> access, use or otherwise deal with
                      the Services or any part thereof (including the Platform
                      and any Company Materials) except solely for your own
                      non-commercial purposes;
                    </li>
                    <li>
                      <strong>2.3.2</strong>. copy, modify or create derivative
                      works or improvements of the Services or any part thereof
                      (including the Platform and any Company Materials);
                    </li>
                    <li>
                      <strong>2.3.3</strong>. rent, lease, lend, sell,
                      sublicense, assign, distribute, publish, transfer or
                      otherwise make available any part of the Services to any
                      other Person, including on or in connection with the
                      internet or any time-sharing, service bureau, software as
                      a service, cloud or other technology or service;
                    </li>
                    <li>
                      <strong>2.3.4</strong>. reverse engineer, disassemble,
                      decompile, decode, adapt or otherwise attempt to derive or
                      gain access to the source code of the Platform in whole or
                      in part;
                    </li>
                    <li>
                      <strong>2.3.5</strong>. bypass or breach any security
                      device or protection used by the Platform or access or use
                      the Services or any part thereof other than through the
                      use of your valid user name, identification number,
                      password, license or security key, security token, PIN or
                      other security code, method, technology or device used,
                      alone or in combination, to verify an individual’s
                      identity and authorization to access and use the Services
                      or part thereof.
                    </li>
                    <li>
                      <strong>2.3.6</strong>. knowingly input, upload, transmit
                      or otherwise provide to or through the Platform any
                      information or materials that are unlawful or injurious,
                      or contain, transmit or activate any Viruses;
                    </li>
                    <li>
                      <strong>2.3.7</strong>. damage, destroy, disrupt, disable,
                      impair, interfere with or otherwise impede or harm in any
                      manner the Services or any part thereof (including the
                      Platform and any Company Materials) or the Company’s
                      provision of the Services or any part thereof to any third
                      party, in whole or in part;
                    </li>
                    <li>
                      <strong>2.3.8</strong>. remove, delete, alter or obscure
                      any trademarks, Company Materials, specifications for the
                      Platform, warranties or disclaimers, or any notices
                      regarding any copyright, trademark, patent or other
                      Intellectual Property Rights or other proprietary rights
                      from the Platform or any Company Materials, including any
                      copy thereof;
                    </li>
                    <li>
                      <strong>2.3.9</strong>. access or use the Services or any
                      part thereof (including the Platform and any Company
                      Materials) in any manner that infringes, misappropriates
                      or otherwise violates any Intellectual Property Right or
                      other rights of the Company or any third party, or that
                      violates any Applicable Law; or,
                    </li>
                    <li>
                      <strong>2.3.10</strong>. access or use the Services or any
                      part thereof (including the Platform and any Company
                      Materials) for purposes of your or any other party’s
                      development, provision or use of a competing software
                      service, product or technology.
                    </li>
                    <li>
                      <strong>2.3.11</strong>. access or use the Services or any
                      part thereof (including the Platform and any Company
                      Materials) for purposes of soliciting business without 
                      prior written permission from Auto Genius.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="list-none">
              <ul>
                <li>
                  <strong>
                    2.4 Responsibility for Users; Unauthorized Use.{" "}
                  </strong>
                  You are solely and fully responsible and liable for any and
                  all activities done through or in connection with any login
                  credentials or passwords associated with your Membership or
                  other access or use of the Services, whether or not done by
                  you or a third party, whether with your permission and/or
                  knowledge or not. For the avoidance of doubt, this includes
                  any and all use or other interactions with the Platform or any
                  Company Materials by any Person acting at your direction or on
                  your behalf in each case as if such Person was your own
                  employee. You shall employ all necessary commercially
                  reasonable administrative, physical, and technical safeguards,
                  consistent with industry-accepted methods and standards, to
                  prevent the unauthorized access, use, and disclosure of the
                  Services, including the Platform and any Company Materials.
                </li>
              </ul>
            </li>
          </ul>
          <ol start="3">
            <li>
              <strong>3 Membership; Membership FEES.</strong>
              <ul>
                <li>
                  <strong>3.1. Services Are Only For Geniuses</strong>. Except
                  where the Company has expressly stated that a particular
                  Service is available to non-Geniuses, any use or access to the
                  Services other than access to any part of the Platform or any
                  Company Materials is limited to Geniuses in good standing only.
                </li>
                <li>
                  <strong>3.2. Member of a Specific Genius Community. </strong>Unless
                  the Company agrees otherwise in writing, the benefits of your
                  Membership shall be limited to those rights to access and use
                  the Services that the Company provides to Geniuses of the
                  Genius Community you are a Genius of.
                </li>
                <li>
                  <strong>
                    3.3. Your Membership Application and Other Information
                    Related to your Membership. &nbsp;
                  </strong>
                  <ul>
                    <li>
                      3.3.1. You hereby represent, warrant, and covenant that
                      any and all information that you have included in your
                      Membership application or that you have otherwise
                      previously or will in the future provide to the Company at
                      any time was at the time of disclosure, and remains
                      currently, true, correct, and without any material
                      omission, and you covenant to update that information in
                      the event that it is no longer entirely true, correct and
                      complete. You can always update any information you have
                      included in your Membership application or that you have
                      otherwise provided to us by contacting one of the 
                      founders of Auto Genius, from within the community. 
                      .
                    </li>
                    <li>
                      3.3.2. You acknowledge and agree that any login
                      credentials and passwords associated with your Membership
                      or you otherwise have in connection with any of the
                      Services are for your use only. No other Person is
                      permitted to use any of your login credentials or
                      passwords to access or use any of the Services for any
                      reason. You are responsible for maintaining the
                      confidentiality of your personal Membership-related
                      information and other Services-related information,
                      including any login credentials and passwords associated
                      with your Membership and or your access to and use of the
                      Services.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    3.4. Term of Membership; Cancelling Your Membership
                  </strong>
                  .
                  <ul>
                    <li>
                      <strong>3.4.1.</strong> Your Membership, and your rights
                      and obligations as a Genius, shall continue until your
                      Membership expires or is terminated by either yourself or
                      the Company.
                    </li>
                    <li>
                      <strong>3.4.2</strong>. You may cancel your Membership at
                      any time by emailing the Company at success@joinAuto
                      Genius.com with seven (7) days notice of your Membership
                      renewal and agree to an exit interview. Unless you tell us
                      otherwise, the cancellation will go into effect as soon as
                      it is processed. If you cancel your Membership, we will
                      not make any further automatic charges to your Payment
                      Card (defined below). For the avoidance of doubt, the
                      Company does not offer any refunds or credits if your
                      Membership is canceled for any reason.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>3.5. Membership Fees and Billing</strong>.
                  <ul>
                    <li>
                      <strong>3.5.1</strong>. a Genius’s Membership fee (“
                      <strong>Membership Fee</strong>”) shall initially be the
                      amount that was communicated to that Member during the
                      Company’s process for approving her or his Membership.
                      <strong>
                        {" "}
                        THE COMPANY RESERVES THE RIGHT TO MODIFY YOUR Membership
                        FEE (BY RAISING, LOWERING, OR OTHERWISE MODIFYING YOUR
                        Membership FEE) AS IT SEES FIT IN ITS SOLE DISCRETION
                        AND AT ANY TIME
                      </strong>{" "}
                      by directly notifying you or by announcing a modification
                      of Membership Fees through the Platform. No other notice
                      to you is required for any such modification.
                    </li>
                    <li>
                      <strong>3.5.2</strong>. The Membership Fee for your
                      Membership will be automatically billed to the specific
                      credit card, debit card or other payment card you have
                      provided for this purpose (your “
                      <strong>Payment Card</strong>”).{" "}
                      <strong>
                        BY SUBMITTING AN APPLICATION FOR Membership AND
                        PROVIDING YOUR BILLING INFORMATION, YOU AUTHORIZE THE
                        COMPANY TO AUTOMATICALLY RENEW YOUR Membership AND
                        AUTOMATICALLY CHARGE YOUR PAYMENT CARD THE APPLICABLE
                        Membership FEE ON AN ANNUAL OR MONTHLY BASIS UNTIL YOU
                        CANCEL.
                      </strong>{" "}
                      Your Payment Card will continue to be automatically
                      charged the applicable Membership Fees unless and until
                      your Membership has ended in accordance with Section 3.4.
                    </li>
                    <li>
                      <strong>3.5.3</strong>. If you choose the monthly
                      Membership payment plan, your monthly Membership Fee will
                      be automatically charged to your Payment Card on the day
                      of checkout to officially start your Membership.. Your
                      Payment Card will then be automatically charged on each
                      subsequent monthly anniversary of that first automatic
                      charge date as long as you are still a Genius at such
                      time.
                    </li>
                    <li>
                      <strong>3.5.4</strong>. If you choose the annual
                      Membership payment plan, your annual Membership Fee will
                      be automatically charged to your Payment Card on the day
                      of checkout to officially start your Membership. Your
                      Payment Card will then be automatically charged on each
                      subsequent annual anniversary of that first automatic
                      charge date as long as you are still a Genius at such
                      time.
                    </li>
                    <li>
                      <strong>3.5.5</strong>. No refund of billed Membership
                      Fees will be provided even if your Membership is
                      terminated in the middle, rather than at the end, of a
                      calendar month (if you have a monthly payment plan) or
                      during the middle of a Geniusship year (if you have an
                      annual payment plan). This rule applies regardless of
                      whether your Membership was terminated by you or by the
                      Company or why it was terminated.
                    </li>
                    <li>
                      <strong>3.5.6</strong>.{" "}
                      <strong>
                        IF YOU RECEIVED A PROMOTIONAL Membership FEE OR FREE
                        TRIAL, COMPANY WILL AUTOMATICALLY RENEW YOUR Membership
                        AND CHARGE YOUR PAYMENT CARD AT THE STANDARD (I.E.,
                        NON-PROMOTIONAL) Membership FEE UPON EXPIRATION OF THE
                        PROMOTIONAL OR FREE TRIAL PERIOD.
                      </strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>3.6. Billing Information; Payment Processor. </strong>
                  <ul>
                    <li>
                      <strong>3.6.1</strong>. In order to pay for your
                      Membership Fees, you will be required to supply your
                      Payment Card number and expiration date, your billing
                      address, and other relevant billing information (“
                      <strong>Billing Information</strong>”). You agree to
                      provide accurate and up-to-date Billing Information at
                      such time and to keep your Billing Information accurate
                      and up-to-date in the event there are any changes.
                    </li>
                    <li>
                      <strong>3.6.2</strong>. Your Billing Information will be
                      handled by one or more third-party payment processors that
                      we have contracted with, not by the Company itself. You
                      should be aware that online payment transactions are
                      subject to validation checks by our payment processor and
                      your Payment Card issuer and we are not responsible if
                      your Payment Card issuer declines to authorize payment for
                      any reason. For your protection, our payment processor
                      uses various fraud prevention protocols and industry
                      standard verification systems to reduce fraud and you
                      authorize it to verify and authenticate your payment
                      information. Please note that it is possible that your
                      Payment Card issuer may charge you an online handling fee
                      or processing fee. We are not responsible for this fee. In
                      some jurisdictions, our payment processor may use third
                      parties under strict confidentiality and data protection
                      requirements for the purposes of payment processing
                      services. For more information about how we use your
                      Billing Information, including how we may share it with
                      third-party payment processors and vendors, please review
                      the{" "}
                      <Link to="/privacy-policy">
                        Privacy Policy
                      </Link>
                      .
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>3.7. Free Trials.</strong> The Company, in its sole
                  discretion, may offer you an opportunity to access or use some
                  or all of the Services – for instance, to attend an Event —
                  free of charge for a limited period of time (a “
                  <strong>Free Trial</strong>”). For the avoidance of doubt, if
                  you accept such an invitation for a Free Trial, you will be
                  deemed to have accepted all these Terms save those related to
                  any billing or payment matters.
                </li>
                <li>
                  <strong>3.8. Taxes</strong>. All Membership Fees and other
                  amounts payable by you hereunder are exclusive of all taxes,
                  assessments, charges, duties, fees, levies, and other charges
                  of a Governmental Authority, any credit card, debit card or
                  payment-related fees, or similar assessments, charges or fees.
                  You shall be solely responsible for all such amounts other
                  than taxes imposed on the Company’s income, profits or net
                  worth.
                </li>
                <li>
                  <strong>3.9. Failure To Timely Pay Membership Fees</strong>.
                  If you fail to pay your Membership Fees in full when due then,
                  in addition to all other remedies that may be available to the
                  Company:
                  <ul>
                    <li>
                      <strong>3.9.1</strong>. The Company may charge interest on
                      the past due amount at the rate of 1.5% per month
                      calculated daily and compounded monthly or, if lower, the
                      highest rate permitted under Applicable Law; and
                    </li>
                    <li>
                      <strong>3.9.2</strong>. The Company may without notice
                      suspend your right and/or ability to access and/or use any
                      or all of the Services until all past due amounts and
                      interest thereon have been paid. The Company shall have no
                      obligation or liability to you or any other Person by
                      reason of any such suspension.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>3.10. Collections.</strong> You acknowledge you shall
                  be solely responsible and will pay on demand all costs of
                  collection, including all reasonable attorneys’ fees, incurred
                  by the Company in enforcing any of your payment obligations
                  hereunder.
                </li>
                <li>
                  <strong>3.11. Changes to Memberships.</strong> The Company
                  reserves the right to modify or discontinue any type of
                  Membership, any required Membership Fees, benefits, features
                  and limitations at any time, at its sole discretion, by
                  notifying a Genius directly about any such modification or
                  discontinuance or by announcing such modification or
                  discontinuance through the Platform (each, a “
                  <strong>Membership Change</strong>”). If you have already paid
                  for a Geniusship when a Geniusship Change affecting your
                  Membership Fees is made, any change in Membership Fees will go
                  into effect for you at the next renewal of your Membership
                  (provided that we may impose such a Geniusship Change sooner
                  upon written notice to you in extraordinary circumstances).
                  All other Membership Changes shall be effective upon
                  notification to you in accordance this Section. IF YOU DO NOT
                  AGREE TO ANY Membership CHANGE, YOUR SOLE REMEDY IS TO CANCEL
                  YOUR Membership AND TO IMMEDIATELY DISCONTINUE ANY ACCESS TO
                  OR USE OF ANY OF THE SERVICES.
                </li>
              </ul>
            </li>
            <li>
              <strong>
                4. CODE OF CONDUCT; EVENTS; INTERACTIVE FEATURES;{" "}
              </strong>
              <strong>MEMBER CONTRIBUTIONS.</strong>
              <ul>
                <li>
                  <strong>4.1. Code of Conduct</strong>. You agree that your
                  involvement in all Events, all other aspects of the Company’s
                  Communities, and all Member Contributions (defined below) that
                  you may make shall comply with the Company’s Code of Conduct.
                </li>
                <li>
                  <strong>4.2. Interactive Features</strong>. The Services may
                  include message boards, chat rooms, personal web pages or
                  profiles, forums, bulletin boards, interactive instant
                  messaging channels or groups, interactive real-time audio
                  and/or video conversation platforms, and other interactive
                  features that allow Geniuses to post, submit, publish, display,
                  or transmit to other Geniuses or other Persons (hereinafter,
                  “post”) content or materials on or through the Platform. For
                  the avoidance of doubt, any such interactive features shall be
                  deemed part of the Platform.
                </li>
                <li>
                  <strong>
                    4.3. Events; Additional Terms and Conditions For Events
                  </strong>
                  . The Services may include Events of different sorts, varying
                  in duration, tone, expected level of participation or
                  otherwise. You hereby acknowledge and agree to each of the
                  following terms and conditions as lawful consideration for
                  being permitted by the Company to participate in such Event:
                </li>
                <li>
                  4.3.1. YOU ARE AWARE AND UNDERSTAND THAT AN EVENT MAY INCLUDE
                  ACTIVITIES THAT ARE DANGEROUS AND THAT INVOLVE THE RISK OF
                  SERIOUS INJURY AND/OR DEATH AND/OR PROPERTY DAMAGE. IF THAT IS
                  THE CASE, YOU FURTHER ACKNOWLEDGE THAT ANY INJURIES THAT YOU
                  MAY SUSTAIN MAY BE COMPOUNDED BY NEGLIGENT EMERGENCY RESPONSE
                  BY THE COMPANY OR OTHERS. PROVIDED THAT THE COMPANY HAS
                  INFORMED YOU AS TO THE NATURE OF ANY SUCH DANGEROUS
                  ACTIVITIES, YOU ACKNOWLEDGE THAT YOU ARE VOLUNTARILY
                  PARTICIPATING IN ANY SUCH ACTIVITIES WITH KNOWLEDGE OF THE
                  DANGER INVOLVED AND HEREBY AGREE TO ACCEPT AND ASSUME ANY AND
                  ALL RISKS OF INJURY, DEATH, OR PROPERTY DAMAGE, WHETHER CAUSED
                  BY THE COMPANY’S NEGLIGENCE OR OTHERWISE.
                  <ul>
                    <li>
                      <strong>4.3.2</strong>. In addition to any other waiver
                      and release of Claims that may be included in these Terms,
                      you hereby expressly waive and release any and all Claims,
                      now known or hereafter known, against the Company, its
                      Personnel, and its and their successors, and assigns
                      (collectively, “Event Releasees”) arising out of or
                      attributable to the activities taking place at or in
                      connection with an Event, whether arising out of the
                      negligence of the Company, any other Event Releasee or
                      otherwise. You covenant not to make or bring any such
                      Claim against the Company or any other Event Releasee and
                      forever release and discharge the Company and all other
                      Event Releasees from liability under such Claims.
                    </li>
                    <li>
                      <strong>4.3.3</strong>. You hereby acknowledge and agree
                      that your attendance at and participation in any Event may
                      be recorded by one or more methods and in any audio,
                      video, digital or other formats (an “
                      <strong>Event Recording</strong>”), by, on behalf of or at
                      the direction of the Company, and you hereby irrevocably
                      grant and license to the Company and its affiliates,
                      successors, licensees, agents, and assigns (“
                      <strong>Authorized Persons</strong>“) the rights to
                      digitize, modify, alter, edit, adapt, create derivative
                      works, display, publicly perform, exhibit, transmit,
                      broadcast, reproduce, exploit, sell, rent, license,
                      otherwise use, and permit others to use, an Event
                      Recording, including your name, image, likeness,
                      appearance, and voice as they appear in an Event
                      Recording, in perpetuity throughout the universe and in
                      any medium or format whatsoever now existing or hereafter
                      created, including internet streaming and downloading,
                      websites, other digital transmission or delivery methods,
                      mobile applications, television broadcast, cablecast, and
                      satellite, home video, video on demand, radio, and print
                      publications, on any platform, including televisions,
                      computers, and mobile devices, without further consent
                      from or any royalty, payment, or other compensation to
                      yourself except as these Terms may expressly provide
                      otherwise.
                    </li>
                    <li>
                      <strong>4.3.4</strong>. Further, you hereby irrevocably
                      permit, authorize, and license the Company and the
                      Authorized Persons to identify yourself by name and use
                      your name, likeness, appearance, voice, professional and
                      personal biographical information, signature, other
                      personal characteristics, and private information, and all
                      Company Materials created by or on behalf of the Company
                      that incorporate any of the foregoing, in connection with
                      any use of an Event Recording by, on behalf of or at the
                      direction of the Company in perpetuity throughout the
                      universe and in any medium or format whatsoever now
                      existing or hereafter created without further consent from
                      or any royalty, payment, or other compensation to you.
                    </li>
                    <li>
                      <strong>4.3.5</strong>. You agree that the Company is and
                      will be the sole and exclusive owner of all right, title,
                      and interest in and to an Event Recording and anything
                      made from or that incorporates any part of an Event
                      Recording (whether such constitute Company Materials or
                      not), including all copyrights and other Intellectual
                      Property Rights therein, in perpetuity throughout the
                      universe. In furtherance of the foregoing, you agree that
                      the results and proceeds of your participation in
                      connection with an Event Recording are works made for hire
                      for the Company as defined in Section 101 of the Copyright
                      Act of 1976. To the extent an Event Recording or any part
                      thereof does not qualify as, or otherwise fails to be,
                      work made for hire, you shall, and hereby do, (a) assign,
                      transfer, and otherwise convey to the Company, irrevocably
                      and in perpetuity, throughout the universe, all of your
                      right, title, and interest, if any, in and to such Event
                      Recording, including all copyright and other Intellectual
                      Property Rights, all registration, renewal, and reversion
                      rights, and the right to register and sue to enforce such
                      copyrights against infringers; and (b) irrevocably waive
                      any and all Claims you may now or hereafter have in any
                      jurisdiction to so-called “moral rights” or rights of
                      droit moral in such Event Recording.
                    </li>
                    <li>
                      <strong>4.3.6</strong>. The Company has no obligation to
                      use an Event Recording in any way. You acknowledge and
                      agree that you have no right to review or approve an Event
                      Recording, any part thereof, or any use of or
                      incorporation of an Event Recording or part thereof in any
                      other materials before such is used in any way by the
                      Company or any third party or at any other time, and that
                      the Company has no liability to you for any editing or
                      alteration of an Event Recording or for any distortion or
                      other effects resulting from the Company’s or a third
                      party’s editing, alteration, or use of an Event Recording
                      or the presentation of yourself. Acknowledgment or credit
                      of you in connection with an Event Recording, if any,
                      shall be determined by the Company in its sole discretion.
                    </li>
                    <li>
                      <strong>4.3.7</strong>. To the fullest extent permitted by
                      Applicable Law, you (a) hereby irrevocably waive all legal
                      and equitable rights relating to any and all Claims
                      arising directly or indirectly from the Authorized
                      Persons’ exercise of their rights under these Terms or the
                      production, exhibition, advertising, promotion,
                      exploitation, or other use of an Event Recording, and
                      whether or not resulting in whole or in part from the
                      negligence of the Company or any other Person, (b)
                      covenant not to make or bring any such Claim against any
                      Authorized Persons and their agents, employees, and
                      representatives, and (c) forever release and discharge the
                      Authorized Persons from liability under such Claims.
                    </li>
                    <li>
                      <strong>4.3.8</strong>. You further represent and warrant
                      to the Company that your participation in any Event and in
                      any Event Recording, and the Authorized Persons’ use of
                      such Event Recording and the rights and license granted
                      hereunder, do not, and will not, violate any right
                      (including any Intellectual Property Right, right to
                      privacy or right of publicity) of, or conflict with, or
                      violate any contract or agreement with or commitment made
                      to, any Person, and that no consent or authorization from,
                      or any payment to, any third party is required.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>4.4. Definition of “Member Contribution.” </strong>“
                  <strong>Member Contribution</strong>” means anything that you
                  express or do (or omit to express or do) in connection with
                  any Genius Community or otherwise connection with any other Member or
                  other Person accessing or using any of the Services. Your
                  “Membership Contributions” also include your actions (i.e.
                  anything you say or do) at any Event or any content or
                  materials you post, upload to or link to through the Platform.
                </li>
                <li>
                  <strong>4.5. Status of Member Contributions</strong>. You
                  acknowledge and agree that, as between you and the Company,
                  your Member Contributions shall be considered non-confidential
                  and non-proprietary. Subject to our{" "}
                  <Link to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  , by making any Member Contribution, you grant us, our
                  licensees and service providers and other vendors, and each of
                  our and their respective licensees, successors, and assigns
                  the right to use, reproduce, modify, perform, display,
                  distribute, and otherwise disclose to third parties any such
                  material for any purpose. In connection therewith, you
                  represent and warrant that:
                  <ul>
                    <li>
                      <strong>4.5.1</strong>. You own or control all rights in
                      and to all your Member Contributions and have the right to
                      grant the license set forth above to us, our licensees,
                      and our service providers or other vendors, and each of
                      our and their respective licensees, successors, and
                      assigns;
                    </li>
                    <li>
                      <strong>4.5.2</strong>. All of your Member Contributions
                      do and will comply with these Terms (including the Code of
                      Conduct); and,
                    </li>
                    <li>
                      <strong>4.5.3</strong>. You understand and acknowledge
                      that you are responsible for all your Member
                      Contributions, and you, not the Company, have full
                      responsibility for any such Member Contribution, including
                      its legality, reliability, accuracy, and appropriateness.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    4.6. Enforcement of Terms Regarding Member Contributions.
                  </strong>{" "}
                  Auto Genius has the right to:
                  <ul>
                    <li>
                      <strong>4.6.1</strong>. Remove from or refuse to post to
                      the Platform any Member Contributions for any or no reason
                      in our sole discretion;
                    </li>
                    <li>
                      <strong>4.6.2</strong>. Take any action with respect to
                      any Member Contribution that we deem necessary or
                      appropriate in our sole discretion, including if we
                      believe that such Member Contribution violates the Terms,
                      including the{" "}
                      <Link to="/code-of-conduct">
                        Code of Conduct
                      </Link>
                      , infringes any Intellectual Property Rights or other
                      rights of any Person, threatens the personal safety of
                      another Member, user of the Services or other Person, or
                      could create any liability for the Company; and,
                    </li>
                    <li>
                      <strong>4.6.3</strong>. Disclose your identity or other
                      information about you to any third party who claims that
                      any Member Contribution made or posted by you violates
                      their rights, including their Intellectual Property Rights
                      or their right to privacy.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Without limiting the foregoing, we have the right to cooperate fully
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            making or posting any Member Contribution. YOU WAIVE AND HOLD
            HARMLESS THE COMPANY, ITS PERSONNEL, LICENSEES, SERVICE PROVIDERS
            AND OTHER VENDORS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
            ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
            INVESTIGATIONS BY ANY SUCH PARTY, ANY LAW ENFORCEMENT AGENCY OR
            OTHER GOVERNMENTAL AUTHORITY.
          </p>
          <ul>
            <li>
              <strong>
                4.7. No Prior Review of Member Contributions; No Liability. The
                Company
              </strong>{" "}
              does not undertake to review Member Contributions before they are
              made (whether at an Event or posted on or through any part of the
              Platform) and cannot ensure prompt removal of any objectionable
              Member Contribution after it has been made or posted. Accordingly,
              we assume no liability for any action or inaction regarding any
              Member Contributions, any violation of the Code of Conduct, or any
              other transmissions, communications, or content provided by any
              Member, other user or any third party. We have no liability or
              responsibility to anyone for the performance or nonperformance of
              the activities described in this Section by you or any by other
              Member.
            </li>
            <li>
              <strong>4.8. Copyright Infringement.</strong> If you believe that
              any Member Contribution violates your copyright, please contact us with a notice of copyright infringement.
              It is the policy of the Company to terminate the Memberships and
              other rights to access and use the Services of repeat infringers. We will reply to such requests for what additional proof we may require in order to fulfill such as request.
            </li>
            <li>
              <strong>
                4.9. No Reliance on Information and Materials Shared with You.
              </strong>
              <ul>
                <li>
                  <strong>4.9.1</strong>. You acknowledge and agree that, except
                  where the Company expressly states otherwise, any information
                  or materials, including any Company Materials, any Member
                  Contributions made or posted by any other Geniuses or any
                  content provided by another third party, that is presented or
                  shared with you on or through the Services, or otherwise in
                  connection with any Company activities is made available
                  solely for general information purposes. We do not warrant the
                  accuracy, completeness, or usefulness of any such information
                  or materials. Any reliance you place on such information or
                  materials is strictly at your own risk. We disclaim all
                  liability and responsibility arising from any reliance placed
                  on such information or materials by you, any other Member or
                  any other Person using or accessing any part of the Services,
                  or by anyone who may be informed of any of their contents.
                </li>
                <li>
                  <strong>4.9.2</strong>. In addition to the Member
                  Contributions, the Services may include content provided by
                  other third parties, including materials provided by other
                  users, bloggers, journalists, subject-area experts, and
                  third-party licensors, syndicators, aggregators, and/or
                  reporting services. All statements and opinions expressed in
                  any such materials, and all Member Contributions, other
                  articles and responses to questions and other content, other
                  than content or responses authored by or at the direction of
                  the Company itself, are solely the opinions and the
                  responsibility of the Person providing those materials. These
                  materials do not necessarily reflect the opinion of the
                  Company. We are not responsible or liable to you or any third
                  party for the content or accuracy of any materials provided by
                  any third parties.
                </li>
              </ul>
            </li>
            <li>
              <strong>4.10. Linking to the Platform</strong>. You may link to
              our homepage provided you do so in a way that is fair and legal
              and does not damage our reputation or take advantage of it, but
              you must not establish a link in such a way as to suggest any form
              of association, approval, or endorsement on our part without our
              express written consent.
            </li>
          </ul>
          <ol start="5">
            <li>
              <strong>5. THIRD-PARTY PLATFORMS AND SERVICES.</strong>
              <ul>
                <li>
                  <strong>5.1.1</strong>. The Company may use certain
                  third-party websites, services or other resources on the
                  Internet to help provide certain Services (for example, a
                  third-party service that allows for online video conversations
                  between Geniuses). In other instances, the Company may provide
                  links to third-party websites, services or other resources
                  including our partners and social media networks.
                  Collectively, these third-party websites, services, and other
                  resources are referred to in these Terms as “
                  <strong>External Platforms</strong>”.
                </li>
                <li>
                  <strong>5.1.2</strong>. Any link to any External Platform or
                  any arrangements between the Company and an External Platform
                  to help make certain Services available to you are provided
                  solely as a convenience to you and not as an endorsement by
                  the Company of any content on such External Platform that is
                  not solely provided by or at the direction of the Company. We
                  are not responsible for the content of any linked External
                  Platforms and do not make any representations regarding the
                  content or accuracy of any materials on such External
                  Platforms except those that are solely provided by or at the
                  direction of the Company. You should take precautions when
                  downloading files from or otherwise accessing an External
                  Platform to protect your computer and other digital devices
                  from Viruses. If you decide to access any External Platform,
                  including in connection with accessing or using any Services,
                  you do so at your own risk. You acknowledge and agree that the
                  Company shall not be responsible or liable, directly or
                  indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  content, technology, goods, products or services available on
                  or through any External Platforms, whether or not that use or
                  reliance is related to your accessing or using any Services.
                </li>
                <li>
                  <strong>5.1.3</strong>. In the event that you use any External
                  Platform to access or use any of the Services, you agree to
                  follow all terms, conditions, and notices applicable to
                  accessing or using such External Platform. If you are not
                  willing to accept all of an External Platform’s terms and
                  conditions, you are not permitted to use that External
                  Platform to access or use any of the Services.
                </li>
              </ul>
            </li>
            <li>
              <strong>6. MAINTENANCE AND AVAILABILITY OF THE SERVICES</strong>.
              The Company may take offline or otherwise make unavailable the
              Services (including any part of the Platform or any Company
              Materials) or any portion thereof because of any scheduled or
              emergency maintenance, events of force majeure, malicious attacks
              on the Platform, issues associated with your computing devices,
              local area networks or internet service provider connections,
              internal organizational matters or for any other reasons.
            </li>
            <li>
              <strong>7. MODIFICATIONS TO THE SERVICES</strong>. The Company
              reserves the right to add, remove, amend, modify and/or update any
              part of the Services, including the Platform and the Company
              Materials, or any component, feature or selection thereof at any
              time as it sees fit in its sole discretion. The Company also
              reserves the right to charge a separate or additional fee or
              charge for any part of the Services that was previously provided
              free of any additional charge at any time.
            </li>
            <li>
              <strong>8. ANONYMIZED INFORMATION</strong>. Subject to the terms
              of our Privacy Policy, the Company may compile and use anonymized,
              aggregated data generated by your accessing and/or use of the
              Services or any part thereof for purposes of improving the
              Services or for any other purposes as the Company determines
              appropriate, but only if such information does not identify such
              data as originating from you (such anonymized, aggregated data,
              “Anonymized Data”). For the avoidance of doubt, the Company shall
              be the sole owner of, and hold all right, title, and interest to,
              any and all Anonymized Data and you shall have no such ownership,
              right, title or interest.
            </li>
            <li>
              9. <strong>FEEDBACK</strong>. You may, from time to time and in
              its sole discretion, make suggestions for changes, modifications
              or improvements to the Company regarding all or part of the
              Services or any other matter or subject related thereto (“
              <strong>Feedback</strong>”). All Feedback shall be owned solely by
              the Company (including all Intellectual Property Rights therein
              and thereto) and, if provided in a private or confidential setting
              or context, shall also be the Company’s Confidential Information.
              You shall and hereby do make all assignments necessary for the
              Company to achieve such ownership.
            </li>
            <li>
              <strong>10. YOUR OBLIGATIONS WITH REGARDS TO THE PLATFORM</strong>
              . You shall be solely responsible for (a) obtaining and
              maintaining all computer hardware, software, and communications
              equipment you need to access the Platform including any
              Interactive Features and any Company Materials provided on or
              through the Platform, (b) paying all third-party access, usage or
              service charges or fees incurred while accessing the Platform, and
              (c) implementing, maintaining, and updating all necessary and
              proper procedures and software for safeguarding against Viruses.
              You shall (i) abide by all Applicable Laws in connection with
              these Terms and the Platform, (ii) use the Platform or any portion
              thereof only for those legal purposes permitted herein, and (iii)
              comply with all regulations, policies, and procedures of any
              networks and telecommunications providers involved in accessing or
              using the Platform.
            </li>
            <li>
              <strong>11. CONFIDENTIALITY</strong>. “
              <strong>Confidential Information</strong>” includes any private or
              confidential information about the Company, the Services or any
              part thereof, the Company’s past, present or future business,
              operations, products, services, partners, Personnel, vendors,
              customers or technology, and any agreement, arrangement or
              understanding that the Company and you are both parties to. To the
              extent that you receive or otherwise obtain any Confidential
              Information, you shall hold that Confidential Information in
              confidence, not share or transfer it to any other Person, and use
              it only as expressly permitted by the Company. For the avoidance
              of doubt, any information that the Company shares generally with,
              or with a large group of, its Geniuses shall be deemed not to be
              Confidential Information. At any time the Company so requests, or
              automatically and immediately in the event that your Membership
              and/or any other rights you may have to access or use the Services
              are terminated, you shall return (if tangible copies) or
              permanently delete or destroy (if digital or otherwise intangible
              copies) all copies of any Confidential Information you possess or
              otherwise have control of.
            </li>
            <li>
              <strong>12. REPRESENTATIONS AND WARRANTIES</strong>. You represent
              and warrant that the following is accurate and true as of the date
              on which you apply for a Geniusship or, if you have not applied
              for Membership, the date on which you first use or access any of
              the Services, and you covenant that the following will remain
              accurate and true during the entire time you, or others at your
              direction or on your behalf, access or use any of the Services:
              (a) you are a natural Person with legal capacity over 18 years of
              age or you are legal entity properly incorporated, formed or
              organized (as the case may be) and existing under the laws of the
              jurisdictions of its incorporation, formation or organization; (b)
              you have the authority and capacity to enter into the agreement
              constituted by these Terms; (c) these Terms constitute a legal,
              valid, and binding obligation, enforceable against you according
              to its terms; (e) you are not under any restriction or obligation
              that it would be reasonable to conclude may affect your
              performance of your obligations under these Terms; and (f) your
              entry into the agreement constituted by these Terms and your
              performance of your obligations under these Terms will not breach
              or result in a default under (i) if you are a legal entity, your
              charter, articles, bylaws, limited liability company agreement or
              any other governing document or agreement to which you are
              subject, (ii) any Applicable Law to which you are subject, or
              (iii) any agreement to which you are a party or by which you are
              bound.
            </li>
            <li>
              <strong>13. TERMINATION AND EXPIRATION.</strong>
              <ul>
                <li>
                  13.1.1. The Company may, in our sole discretion, change,
                  suspend or terminate all or any part of your Membership, your
                  right to use or access all or any part of the Services
                  (including all or any portion of the Platform or the Company
                  Materials), at any time and without prior notice or liability,
                  if you breach these Terms or if the Company is unable to
                  continue to offer all or any part of the Services. In the
                  event you cancel your Membership or it is otherwise
                  terminated, regardless of reason, you shall immediately and
                  completely cease any and all accessing or use of the Services.
                </li>
                <li>
                  13.1.2. Notwithstanding the termination, for any or no reason,
                  of your Membership, the agreement formed between the Parties
                  by these Terms, and/or any other rights you may have to access
                  or use the Services, you acknowledge and agree that Sections
                  1-11 and 1-18, as well as any other terms that by their nature
                  are intended to survive the termination of your Membership,
                  the agreement formed between the Parties by these Terms and/or
                  any other rights you may have to access or use the Services,
                  shall survive such termination.
                </li>
              </ul>
            </li>
            <li>
              <strong>14. INDEMNIFICATION</strong>. You shall indemnify, defend
              and hold harmless the Company and its Personnel (each such Person,
              an “<strong>Indemnitee</strong>”) from and against any and all
              Claims arising from, related to or otherwise occurring in
              connection with any actual or alleged (a) violation of these Terms
              by you, (b) a violation, infringement or misappropriation of any
              third party’s Intellectual Property Rights, proprietary rights,
              privacy or personality rights or other rights by you, or (c) gross
              negligence or willful misconduct by you. For the avoidance of
              doubt, the remedies set forth in this Section are in addition to,
              not instead of, any other remedies available to an Indemnitee
              pursuant to these Terms or otherwise available to an Indemnitee
              under Applicable Law. The Company reserves the right to assume the
              exclusive defense and control (at your sole expense) of any matter
              that is subject to indemnification under this Section. In such
              case, you agree to cooperate (at your sole expense) with any
              reasonable requests assisting our defense of such matter.
            </li>
            <li>
              <strong>15. DISCLAIMERS; LIMITATIONS OF LIABILITY. </strong>
              <ul>
                <li>
                  <strong>15.1. Disclaimer of Warranties</strong>. THE COMPANY
                  AND ITS PERSONNEL MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT
                  THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS,
                  SECURITY, ERROR-FREE FUNCTIONING, VIRUS-FREE STATUS OR
                  ACCURACY OF THE SERVICES, INCLUDING ANY EVENT, THE PLATFORM,
                  THE COMPANY MATERIALS, OR ANY COMPONENT, FEATURE OR PORTION
                  THEREOF OR ANY INFORMATION OR DATA MADE AVAILABLE FROM ANY OF
                  THEM FOR ANY PURPOSE. TO THE FULLEST EXTENT PERMITTED BY
                  APPLICABLE LAW, THE SERVICES, ANY PORTION THEREOF, AND ANY
                  INFORMATION, DATA OR MATERIALS (INCLUDING BUT NOT LIMITED TO
                  THE COMPANY MATERIALS) MADE AVAILABLE FROM, ON OR THROUGH THE
                  SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT
                  WARRANTY OR CONDITION OF ANY KIND. THE COMPANY DISCLAIMS ALL
                  WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                  IMPLIED OR STATUTORY, WITH REGARD TO THE SERVICES, INCLUDING
                  ALL EVENTS, THE PLATFORM, AND THE COMPANY MATERIALS, INCLUDING
                  ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                  TO THE EXTENT THAT THE COMPANY MAY NOT AS A MATTER OF
                  APPLICABLE LAW DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND
                  DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER
                  SUCH LAW.
                </li>
                <li>
                  <strong>15.2. Limitation of Liability</strong>. TO THE EXTENT
                  PERMITTED BY LAW, IN NO EVENT SHALL THE COMPANY OR ANY OF ITS
                  PERSONNEL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                  PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, ARISING FROM
                  YOUR ACCESS TO OR USE OF THE SERVICES OR ANY PART THEREOF,
                  INCLUDING DAMAGES FOR LOST PROFITS, LOST DATA, COMPUTER
                  FAILURE, OR THE VIOLATION OF YOUR RIGHTS BY ANY THIRD PARTY,
                  EVEN IF YOU HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF AND
                  REGARDLESS OF THE LEGAL OR EQUITABLE THEORY UPON WHICH THE
                  CLAIM IS BASED. IN THE EVENT THAT THE COMPANY OR ANY OF ITS
                  PERSONNEL ARE DETERMINED TO HAVE ANY LIABILITY HEREUNDER, THE
                  PARTIES AGREE THAT THE AGGREGATE LIABILITY OF THE COMPANY OR
                  ITS PERSONNEL WILL BE LIMITED TO THE LESSER OF TEN THOUSAND
                  U.S. DOLLARS OR THE TOTAL AMOUNT YOU HAVE ACTUALLY PAID TO THE
                  COMPANY (FOR EXAMPLE, YOUR Membership FEES) IN THE
                  TWELVE-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO A
                  CLAIM. YOU UNDERSTAND AND AGREE THAT ABSENT YOUR AGREEMENT TO
                  THIS LIMITATION OF LIABILITY, THE COMPANY WOULD NOT PROVIDE
                  YOU WITH a Geniusship OR MAKE AVAILABLE ANY OF THE SERVICES TO
                  YOU.
                </li>
                <li>
                  <strong>15.3</strong>. Some jurisdictions do not allow the
                  exclusion of implied warranties or limitation of liability for
                  incidental or consequential damages, so the above limitations
                  or exclusions may not apply to you. IN SUCH JURISDICTIONS, THE
                  LIABILITY OF THE COMPANY AND ITS PERSONNEL SHALL BE LIMITED TO
                  THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW AND NOTHING IN
                  THESE TERMS SHALL AFFECT ANY LEGAL RIGHTS AND REMEDIES YOU
                  HAVE UNDER ANY LOCAL LAW.
                </li>
              </ul>
            </li>
            <li>
              <strong>16. AMENDMENTS AND UPDATES. </strong>These Terms may be
              amended, modified, updated or terminated at any time by the
              Company, provided that the Company notifies you in advance of any
              material amendment, modification or update by posting an amended
              version of the Terms through the Platform (and the date of such
              posting shall be deemed the date of notification) or by notifying
              you of such change(s) in accordance with the notification
              provision included in these Terms. Any such amendment,
              modification or update shall go into effect on the tenth (10th)
              business day following the date of such notification (a “
              <strong>Terms Amendment Date</strong>”). Any use or accessing of
              the Services or any part thereof on or following the applicable
              Terms Amendment Date, except solely to the extent that may be
              required by you to terminate your Membership, shall be deemed to
              constitute your acceptance of such amendment, modification or
              update to these Terms.
            </li>
            <li>
              <strong>17. MISCELLANEOUS.</strong>
              <ul>
                <li>
                  <strong>17.1. Entire Agreement and Severability</strong>.
                  Unless you and the Company expressly agree otherwise in
                  writing, these Terms constitute the entire agreement between
                  you and the Company with respect to the subject matter hereof
                  and any transactions contemplated herein and supersedes all
                  prior or contemporaneous communications and proposals (whether
                  oral, written or electronic) between you and the Company with
                  respect to the same. If any provision of these Terms is found
                  to be unenforceable or invalid by a court or arbitrator, that
                  provision will be limited or eliminated to the minimum extent
                  necessary so that these Terms will otherwise remain in full
                  force and effect and enforceable.
                </li>
                <li>
                  <strong>17.2. Amendment and Modification; Waiver.</strong>{" "}
                  Unless done pursuant to Section 16, no amendment or update to,
                  modification of or rescission, termination or discharge of
                  these Terms is effective unless it is in writing, identified
                  as an amendment to or rescission, termination or discharge of
                  your agreement with the Company constituted by these Terms,
                  and is signed by an authorized representative of each Party.
                  No waiver by a Party of any of these Terms shall be effective
                  unless explicitly set forth in writing and signed by the Party
                  so waiving. Except as otherwise set forth in these Terms, no
                  failure to exercise, or delay in exercising, any rights,
                  remedy, power or privilege arising from these Terms shall
                  operate or be construed as a waiver thereof; nor shall any
                  single or partial exercise of any right, remedy, power or
                  privilege hereunder preclude any other or further exercise
                  thereof or the exercise of any other right, remedy, power or
                  privilege.
                </li>
                <li>
                  <strong>17.3. Assignment.</strong> Neither your Membership,
                  any other rights you have to access or use any of the
                  Services, your agreement with the Company as constituted by
                  these Terms or any of your obligations hereunder may be
                  assigned, sublicensed or delegated, in whole or in part, by
                  you without the Company’s prior written consent. The Company
                  may assign, sublicense, transfer or delegate any of its rights
                  and obligations herein without your consent. These Terms are
                  binding upon and will inure to the benefit of both Parties and
                  their respective successors, heirs, executors, administrators,
                  personal representatives, and permitted assigns. Any purported
                  assignment, delegation or transfer in violation of this
                  Section is null and void.
                </li>
                <li>
                  1<strong>7.4. Relationship of the Parties</strong>. The
                  relationship of the Parties is that of an independent
                  contractor, and no agency, partnership, joint venture, or
                  employment is created as a result of these Terms, and neither
                  Party has any authority of any kind to bind the other Party in
                  any respect.
                </li>
                <li>
                  <strong>17.5. Third-party Beneficiaries.</strong> The Parties
                  agree that any Event Releasee and any Authorized Party shall
                  be a third-party beneficiary of Section 4.3 and any Indemnitee
                  shall be a third-party beneficiary of Section 14. Otherwise,
                  these Terms are for the sole benefit of the Parties and their
                  respective permitted successors and permitted assigns and
                  nothing herein, express or implied, is intended to or shall
                  confer upon any other Person any legal or equitable right,
                  benefit or remedy of any nature whatsoever under or by reason
                  of these Terms.
                </li>
                <li>
                  <strong>17.6. Governing Law</strong>. These Terms, the rights,
                  authorizations, and licenses granted hereunder or any of the
                  transactions contemplated herein, any access or use of any of
                  the Services by you or for which you shall be responsible for
                  as stated herein shall be governed by and construed in
                  accordance with the internal laws of the State of New York
                  without giving effect to any choice or conflict of law
                  provision or rule that would require or permit the application
                  of the laws of any jurisdiction other than those of the State
                  of New York.
                </li>
                <li>
                  <strong>
                    17.7. ARBITRATION CLAUSE &amp; CLASS ACTION WAIVER
                  </strong>
                  .
                  <ul>
                    <li>
                      <strong>17.7.1</strong>. YOU AGREE THAT ALL DISPUTES
                      BETWEEN YOU AND THE COMPANY (WHETHER OR NOT SUCH DISPUTE
                      INVOLVES A THIRD PARTY) WITH REGARD TO YOUR RELATIONSHIP
                      WITH THE COMPANY, INCLUDING WITHOUT LIMITATION DISPUTES
                      RELATED TO THESE TERMS, THE RIGHTS, AUTHORIZATIONS, AND
                      LICENSES GRANTED HEREUNDER OR ANY OF THE TRANSACTIONS
                      CONTEMPLATED HEREIN, ANY ACCESS OR USE OF THE SERVICES,
                      INCLUDING THE PLATFORM, ANY EVENTS, AND THE COMPANY
                      MATERIALS, BY YOU OR FOR WHICH YOU SHALL BE RESPONSIBLE AS
                      STATED HEREIN, WILL BE RESOLVED BY BINDING, INDIVIDUAL
                      ARBITRATION UNDER THE AMERICAN ARBITRATION ASSOCIATION’S
                      RULES FOR ARBITRATION OF CONSUMER-RELATED DISPUTES AND YOU
                      AND THE COMPANY HEREBY EXPRESSLY WAIVE TRIAL BY JURY;
                      PROVIDED, HOWEVER, THAT TO THE EXTENT THAT YOU HAVE IN ANY
                      MANNER VIOLATED OR THREATENED TO VIOLATE THE COMPANY’S
                      INTELLECTUAL PROPERTY RIGHTS, THE COMPANY MAY SEEK
                      INJUNCTIVE OR OTHER APPROPRIATE RELIEF IN ANY STATE OR
                      FEDERAL COURT IN THE STATE OF NEW YORK. DISCOVERY AND
                      RIGHTS TO APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED
                      THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND THE
                      COMPANY WOULD HAVE IN COURT MAY NOT BE AVAILABLE IN
                      ARBITRATION.
                    </li>
                    <li>
                      <strong>17.7.2</strong>. As an alternative, you may bring
                      your Claim in your local “small claims” court, if
                      permitted by that small claims court’s rules and if within
                      such court’s jurisdiction, unless such action is
                      transferred, removed or appealed to a different court. You
                      may bring Claims only on your own behalf. Neither you nor
                      the Company will participate in a class action or
                      class-wide arbitration for any Claims covered by this
                      agreement to arbitrate.
                    </li>
                    <li>
                      <strong>17.7.3</strong>. YOU AGREE THAT YOU ARE HEREBY
                      GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS
                      REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM YOU MAY
                      HAVE AGAINST THE COMPANY INCLUDING ANY RIGHT TO CLASS
                      ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL
                      ARBITRATIONS. You also agree not to participate in Claims
                      brought in a private attorney general or representative
                      capacity, or consolidated Claims involving another
                      Person’s Membership, the Services (including their access
                      to or use of the Services or any part thereof) or any
                      other matter related to the Company if we are a party to
                      the proceeding. This dispute resolution provision will be
                      governed by the Federal Arbitration Act and not by any
                      state law concerning arbitration. In the event the
                      American Arbitration Association is unwilling or unable to
                      set a hearing date within one hundred and sixty (160) days
                      of filing the case, then either we or you can elect to
                      have the arbitration administered instead by the Judicial
                      Arbitration and Mediation Services. Judgment on the award
                      rendered by the arbitrator may be entered in any court
                      having competent jurisdiction. Any provision of Applicable
                      Law notwithstanding, the arbitrator will not have the
                      authority to award damages, remedies or awards that
                      conflict with these Terms. You agree that regardless of
                      any Applicable Law to the contrary, any Claim arising out
                      of, related to or connected with your Membership or your
                      access to or use of the Services or any component thereof
                      (including any matters related to your Membership Fees) or
                      these Terms must be filed within one (1) year after such
                      Claim arose or be forever banned.
                    </li>
                    <li>
                      <strong>17.7.4</strong>. If the prohibition against class
                      actions and other Claims brought on behalf of third
                      parties contained above is found to be unenforceable, then
                      all of the preceding language in this Section 17.7 will be
                      null and void. This agreement to arbitration will survive
                      the termination of your relationship with us.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>17.8. Equitable Relief</strong>. You acknowledge and
                  agree that a breach or threatened breach by you of the terms
                  and conditions set forth in Sections 2.3 or 11 might cause the
                  Company irreparable harm for which monetary damages might not
                  be an adequate remedy and agrees that, in the event of such
                  breach or threatened breach, the Company will be entitled to
                  equitable relief, including a restraining order, an
                  injunction, specific performance and any other relief that may
                  be available from any court, without any requirement to prove
                  actual damages or that monetary damages are not an adequate
                  remedy. Such remedies are not exclusive and are in addition to
                  all other remedies that may be available to the Company at
                  law, in equity or otherwise.
                </li>
                <li>
                  <strong>17.9. Headings; Construction.</strong> Any section,
                  subsection, and paragraph headings in these Terms are for
                  convenience only and shall not affect their interpretation.
                </li>
                <li>
                  <strong>17.10. Notices</strong>.
                  <ul>
                    <li>
                      <strong>17.10.1</strong>. When you use the Platform or
                      send communications to us through the Platform, you are
                      communicating with us electronically. You consent to
                      receive electronically any notices or other communications
                      related to these Terms, any agreement between the Parties
                      or the Services. The Company may communicate with you by
                      email or by posting notices on the Platform. You agree
                      that all agreements, notices, disclosures and other
                      communications that are provided to you electronically
                      satisfy any legal requirement that such communications be
                      in writing. All notices from the Company intended for
                      receipt by you shall be deemed delivered and effective
                      when sent to the email address you provide to us.
                    </li>
                    <li>
                      17.10.2. Any day-to-day communications from you to the
                      Company regarding your Membership and/or your access to or
                      use of the Services may be sent to success@joinAuto
                      Genius.com. Any other communications or notices, including
                      any notice that the Company has breached these Terms, must
                      be sent by personal delivery, nationally recognized
                      courier or certified or registered mail (in each case,
                      return receipt requested, postage prepaid) to the Company,
                      Inc., 200 W 11th St, #4, New York, NY 10014. Except as
                      otherwise provided in these Terms, any communication or
                      notice is effective only (a) on receipt by the receiving
                      Party, and (b) only if the Party giving providing such
                      communication or notice has complied with the requirements
                      of this Section.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>17.11. Force Majeure</strong>. The Company shall not
                  be liable under these Terms for performance delays or for
                  non-performance due to causes beyond its reasonable control.
                </li>
                <li>
                  <strong>17.12. Contacting the Company</strong>. Subject to
                  Section 17.10, you may contact the Company about any matter by
                  emailing us at sam@joinAuto Genius.com or by mail at the
                  Company, Inc., 200 W 11th St, #4, New York, NY 10014, attn.:
                  Member Support.
                </li>
                <li>
                  <strong>
                    17.13. IMPORTANT NOTE TO NEW JERSEY CONSUMERS. If you are
                    accessing or using any of the Services in your capacity as a
                    consumer residing in State of New Jersey, the following
                    provisions of these Terms do not apply to you (and do not
                    limit any rights that you may have) to the extent that they
                    are unenforceable under New Jersey law: (a) the disclaimer
                    of liability for any indirect, incidental, punitive,
                    special, exemplary, or consequential damages of any kind
                    (for example, to the extent unenforceable under the New
                    Jersey Punitive Damages Act, New Jersey Products Liability
                    Act, New Jersey Uniform Commercial Code, and New Jersey
                    Consumer Fraud Act); (b) the limitation on liability for
                    lost profits or loss or misuse of data (for example, to the
                    extent unenforceable under the New Jersey Identity Theft
                    Protection Act and New Jersey Consumer Fraud Act); (c)
                    application of the limitations of liability to the recovery
                    of damages that arise under contract and tort, including
                    negligence, strict liability, or any other theory (for
                    example, to the extent such damages are recoverable by a
                    consumer under New Jersey law, including the New Jersey
                    Products Liability Act); (d) the requirement that you
                    indemnify the Company (for example, to the extent the scope
                    of such indemnity is prohibited under New Jersey law); and
                    (e) the governing law provision (for example, to the extent
                    that your rights as a consumer residing in New Jersey are
                    required to be governed by New Jersey law).
                  </strong>
                </li>
              </ul>
            </li>
            <li>
              <strong>18. ADDITIONAL DEFINITIONS; INTERPRETATION</strong>.
              <ul>
                <li>
                  <strong>18.1. Additional Definitions.</strong> In addition to
                  those capitalized terms defined elsewhere in these Terms, the
                  terms below have the following meanings:
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <strong>“Applicable Law”</strong> means any law (including common
            law), statute, regulation, ordinance, rule, guideline, directive,
            requirement, order, decree, judgment, consent decree, writ,
            injunction, settlement agreement or governmental requirement
            enacted, promulgated or imposed or entered into or agreed by any
            federal, state, provincial, local or foreign government, or any
            subdivision, agency, department, board, commission, instrumentality
            or authority of any thereof, including any court.
          </p>
          <p>
            <strong>“Claim”</strong> means any losses, damages, liabilities,
            deficiencies, claims, actions, judgments, settlements, interest,
            awards, penalties, fines, costs or expenses of whatever kind,
            including reasonable attorneys’ fees and the costs of enforcing any
            right to indemnification hereunder and the cost of pursuing any
            insurance providers, including claims for copyright or trademark
            infringement, infringement of moral rights, libel, defamation,
            invasion of any rights of privacy, violation of rights of publicity,
            physical or emotional injury or distress, or any similar claim or
            cause of action in tort, contract, or any other legal theory, now
            known or hereafter known in any jurisdiction throughout the world.
          </p>
          <p>
            <strong>“Company Materials”</strong> means any content, data or
            information of any kind (audio, video, digital, oral, written or
            otherwise) manuals, instructions or other documents or materials
            that the Company provides or makes available to you in any form or
            medium in connection with the Services or that is otherwise related
            to the Company, its business, Personnel or Geniuses. For the
            avoidance of doubt, “Company Materials” shall also include anything
            which describes the functionality, components, features or
            requirements of the Platform, including any aspect of the
            installation, configuration, integration, operation, use, support or
            maintenance of any component thereof.
          </p>
          <p>
            <strong>“Event”</strong> means an event of any kind operated on
            and/or at the direction of the Company or which the Company has
            otherwise indicated shall be an event that Geniuses can attend as a
            benefit of their Memberships. Events can be in-person, online, by
            telephone, videoconference, or otherwise or can be any combination
            of such means, methods, and formats.
          </p>
          <p>
            <strong>“Governmental Authority”</strong> means any federal, state,
            local, or foreign (a) any federal, state, local, or foreign
            government, and any political subdivision of any of them, (b) agency
            or instrumentality of any such government or political subdivision,
            (c) self-regulated organization or other non-governmental regulatory
            authority or quasi-governmental authority (to the extent that its
            rules, regulations or orders have the force of law), or (d)
            arbitrator, court or tribunal of competent jurisdiction.
          </p>
          <p>
            <strong>“Intellectual Property”</strong> means any and all of the
            following in any jurisdiction throughout the world: (a) trademarks
            and service marks, including all applications and registrations, and
            the goodwill connected with the use of and symbolized by the
            foregoing, (b) copyrights, including all applications and
            registrations related to the foregoing, (c) trade secrets and
            confidential know-how, (d) patents and patent applications, (e)
            websites and internet domain name registrations, and (f) other
            intellectual property and related proprietary rights, interests and
            protections (including all rights to sue and recover and retain
            damages, costs and attorneys’ fees for past, present, and future
            infringement, and any other rights relating to any of the
            foregoing).
          </p>
          <p>
            <strong>“Intellectual Property Rights”</strong> means any and all
            registered and unregistered rights granted, applied for or otherwise
            now or hereafter in existence under or related to Intellectual
            Property including any patent, copyright, trademark, trade secret,
            database protection or other intellectual property rights laws, and
            all similar or equivalent rights or forms of protection, in any part
            of the world.
          </p>
          <p>
            <strong>“Party”</strong> means either you or the Company, and,
            together, you and the Company may be referred to the “Parties”.
          </p>
          <p>
            <strong>“Person”</strong> means (a) any corporation, company,
            limited liability company, partnership, Governmental Authority,
            joint venture, fund, trust, association, syndicate, organization, or
            other entity or group of persons, whether incorporated or not, and
            (b) any individual.
          </p>
          <p>
            <strong>“Personnel”</strong> means, in connection with a Party, the
            employees, officers, directors, managers, managing Geniuses, agents,
            subcontractors, vendors, equity holders, legal and financial
            representatives of such Party.
          </p>
          <p>
            <strong>“Platform”</strong> means, collectively, the Company’s
            websites located at www.joinAuto Genius.com and each other Company
            website, mobile site, service, application, online platform or tool,
            any third-party platform, service or tool that the Company uses to
            provide any functionality to its Geniuses or other users of the
            Services, any other part of the Company’s information technology
            infrastructure, including computers, software, hardware,
            architecture, and related systems, databases, electronic systems
            (including database management systems) and networks (and including
            code related thereto), if any, whether operated directly by the
            Company or through the use of third-party services, as well as any
            new versions, updates, revisions, improvements and modifications of
            any part of the Platform.
          </p>
          <p>
            <strong>“Viruses”</strong> means any computer infections, viruses,
            worms, Trojan horses, and other code that manifest contaminating or
            destructive properties or that otherwise prevents the Company, any
            of its Personnel, anyone acting on the Company’s direction, any
            Member or any other user of any of the Services to access or use all
            or any part of the Services.
          </p>
          <ul>
            <li>
              <strong>18.2. Interpretation.</strong> When a reference is made in
              these Terms to a Section, such reference shall be to a Section of
              these Terms unless otherwise indicated. Whenever the words
              “include,” “includes” or “including” are used in these Terms, they
              shall be deemed to be followed by the words “without limitation.”
              The words “hereof,” “herein” and “hereunder” and words of similar
              import when used in these Terms shall refer to these Terms as a
              whole and not to any particular provision of these Terms. The
              definitions contained in these Terms are applicable to the
              singular as well as the plural forms of such terms and to the
              masculine as well as to the feminine and neuter genders of such
              term. Any agreement, instrument or statute defined or referred to
              herein or in any agreement or instrument that is referred to
              herein means such agreement, instrument or statute as from time to
              time amended, modified or supplemented, including (in the case of
              agreements or instruments) by waiver or consent and (in the case
              of statutes) by succession of comparable successor statutes and
              references to all attachments thereto and instruments incorporated
              therein.
            </li>
          </ul>
        </div>
      </div>
      <hr size="1" className="mt-5 mb-5"/>
    </Layout></>
  );
};

export default aboutus;
